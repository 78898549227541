<template>
    <VForm
        v-slot="{ errors }"
        @submit="onSubmit">
        <div class="flex flex-col gap-6">
            <BaseInput
                v-model="model.email"
                :label="$t('auth.email')"
                rules="required|email|max:255"
                required
                type="email"
                name="email"
                autocomplete="username" />
            <BaseInput
                v-model="model.password"
                :label="$t('auth.password')"
                rules="required"
                required
                type="password"
                name="password"
                autocomplete="current-password" />

            <div class="flex flex-col sm:flex-row gap-4 justify-between items-center">
                <div>
                    <BaseCheckbox
                        v-model="model.remember"
                        name="stayLoggedIn">
                        {{ $t('auth.stayLoggedIn') }}
                    </BaseCheckbox>
                </div>
                <div class="text-sm">
                    <span
                        class="font-semibold text-primary-600 hover:text-primary-500 cursor-pointer"
                        @click.prevent="$emit('authPageVisible', AuthForgotPassword)">
                        {{ $t('auth.forgotPassword') }}
                    </span>
                </div>
            </div>

            <BaseButton
                class="w-full"
                color="primary"
                :disabled="loading || !!Object.keys(errors).length"
                native-type="submit">
                <span v-if="!loading">{{ $t('auth.login') }}</span>
                <span v-else>
                    <LoadingSpinner />
                    {{ $t('auth.pleaseWait') }}
                </span>
            </BaseButton>

            <p class="text-sm leading-6 text-gray-500">
                {{ $t('auth.registerText') }}
                <NuxtLink
                    :to="localePath('application')"
                    class="font-semibold text-primary-600 hover:text-primary-500">
                    {{ $t('auth.applicationLink') }}
                </NuxtLink>
            </p>

            <!--<SocialiteOptions @redirect="redirectToProvider" />-->
        </div>
    </VForm>
</template>

<script setup>
import {useAuthStore} from '@/store-pinia/auth'
import SocialiteOptions from '@/components/pages/auth/SocialiteOptions'
import AuthRegister from "@/components/pages/auth/AuthRegister.vue";
import {useLanguage} from "@/composables/useLanguage";
import AuthForgotPassword from "@/components/pages/auth/AuthForgotPassword.vue";

const authStore = useAuthStore()
const notification = useNotification()
const {$apiRoute} = useNuxtApp()

defineEmits(['authPageVisible'])

const model = ref({
    email: null,
    password: null,
    remember: true
})

const loading = ref(false)

async function redirectToProvider(provider) {
    await $lara.get(
        $apiRoute('auth.redirect', {
            provider,
            organisation: 1 // TODO make dynamic
        })
    ).then(response => {
        // console.log(response)
        // avoid cors errors by redirecting in frontend
        window.open(response, '_self')
    }).catch(error => {
        console.log(error)
        notification.error('Das hat nicht funktioniert')
    })
}

async function onSubmit() {
    loading.value = true

    await $lara.post($apiRoute('spa.login'), {
        body: {...model.value}
    }).then(async response => {
        await authStore.successfulLogin()
        loading.value = false
    }).catch(async error => {
        loading.value = false

        notification.error(error.response._data?.message)
    })
}
</script>